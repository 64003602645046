/*
|--------------------
|     PAGE Metiers
|--------------------
*/

#page-metiers {
  position: relative;

  .title-job {
    font-family: Mulat Addis;
    font-size: 40px;
    line-height: 45px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.8px;
    @include media-breakpoint-down(md) {
      font-size: 32px;
      line-height: initial;
    }
  }
  .block-item {
    display: flex !important;
    vertical-align: top;
    align-items: normal;
    width: 100%;
    .block-item-select {
      opacity: 1;
      width: 100%;
      transition: all ease 0.4s;
      position: relative;

      .circle {
        height: 460px;
        width: 320px;
        border-radius: 200px;
        background-color: #e62a34;
        margin: 80px auto;
        display: flex;
        justify-content: center;

        align-items: center;
        position: sticky;
        top: 80px;

        &:before {
          content: "";
          height: 500px;
          width: 360px;
          border-radius: 200px;
          border: 1px solid black;
          display: flex;
          position: absolute;
        }

        .label {
          position: absolute;
          font-family: "Mulat Addis";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 23px;
          width: fit-content;
          cursor: default;
          width: 130px;
          height: 60px;
          display: flex;
          vertical-align: middle;
          align-items: center;
          span {
            opacity: 1;
            transition: all ease 0.3s;
          }

          &:hover,
          &.active {
            span {
              cursor: pointer;
              color: $red;
              transition: all ease 0.3s;
            }

            &:before {
              background-color: $red;
              transition: all ease 0.3s;
            }
          }

          &.disabled {
            span {
              color: $grey;
              transition: all ease 0.3s;
            }
          }

          &:before {
            content: "";
            height: 16px;
            width: 16px;
            display: block;
            position: absolute;
            border-radius: 50%;
            border: 1px solid $red;
            background-color: $light-blue;
            transition: all ease 0.3s;
          }

          &.item-1 {
            top: -90px;
            left: 102px;
            text-align: center;

            &:before {
              top: 61px;
              left: 53px;
            }
          }

          &.item-2 {
            top: 38px;
            left: inherit;
            right: -145px;

            &:before {
              top: 21px;
              left: -29px;
            }
          }

          &.item-3 {
            top: 197px;
            left: inherit;
            right: -170px;

            &:before {
              top: 20px;
              left: -30px;
            }
          }

          &.item-4 {
            top: 355px;
            left: inherit;
            right: -151px;

            &:before {
              top: 21px;
              left: -32px;
            }
          }

          &.item-5 {
            bottom: -89px;
            left: 102px;
            text-align: center;

            &:before {
              top: -19px;
              left: 53px;
            }
          }

          &.item-6 {
            top: 285px;
            left: -165px;
            display: grid;
            text-align: right;

            &:before {
              top: 23px;
              left: 137px;
            }
          }

          &.item-7 {
            top: 104px;
            left: -163px;
            display: grid;
            text-align: right;

            &:before {
              top: 22px;
              left: 137px;
            }
          }
        }

        .icon {
          height: 420px;
          width: 420px;
          display: flex;
          vertical-align: middle;
          align-items: center;
          margin: auto;

          span {
            background-color: white;
            height: 96px;
            width: 96px;
            margin: auto;
            border-radius: 50%;
            transition: all ease 0.4s;
            img {
              height: 100%;
              width: 65%;
              margin: auto;
              display: block;
            }
          }

          img {
            height: 252px;
            width: 252px;
            margin: auto;
          }

          &.hover {
            span {
              height: 96px;
              width: 96px;
              transition: all ease 0.4s;
            }
          }
        }
      }
    }

    .block-item-content {
      width: 0%;
      padding: 0px;
      border-radius: 24px;
      transition: all ease 0.4s;
      background-color: white;
      transition: all ease 0.4s;
      .item-content {
        display: none;
        padding: 0 40px 0px 0;
        overflow-y: auto;
        transition: all ease 0.4s;
        @include media-breakpoint-down(xl) {
          padding: 0 20px 0px 0;
        }
        @include media-breakpoint-down(lg) {
          padding: 0 20px;
        }
      }
      &.active {
        width: 100%;
        display: block;
        padding: 56px 26px 56px 56px;
        transition: all ease 0.4s;

        @include media-breakpoint-down(xl) {
          width: 75%;
          padding: 36px;
        }

        @include media-breakpoint-down(lg) {
          width: 55%;
          padding: 15px;
        }

        .item-content {
          &.select {
            display: block;
          }
        }
      }

      h2 {
        font-family: "Mulat Addis";
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 44px;
        color: $red;
        @include media-breakpoint-down(xl) {
          font-size: 30px;
          line-height: 34px;
        }
        @include media-breakpoint-down(lg) {
          margin-bottom: 0 !important;
        }
      }
      .desc {
        color: #000;
        font-family: NexaRegular;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }

  .mob-metier {
    .one {
      padding: 15px;

      .block__item {
        width: 100%;
      }

      .block__title {
        font-size: 20px;
        line-height: 24px;
        font-family: "Mulat Addis";
        letter-spacing: 2px;
        position: relative;
        cursor: pointer;
        margin-bottom: 6px;
        padding: 15px 50px 16px 30px;
        width: 100%;
        color: white;
        background-color: $red;
        border-radius: 28px;
        @include media-breakpoint-down(sm) {
          font-size: 18px;
        }
      }

      .block__title::before,
      .block__title::after {
        content: "";
        width: 10px;
        height: 2px;
        background-color: white;
        position: absolute;
        top: 27px;
        transition: all 0.3s ease 0s;
      }

      .block__title:before {
        transform: rotate(40deg);
        right: 30px;
      }

      .block__title::after {
        transform: rotate(-40deg);
        right: 23px;
      }

      .block__title.active::before,
      .block__title.active::after {
        background-color: white;
      }

      .block__title.active::before {
        transform: rotate(-40deg);
      }

      .block__title.active::after {
        transform: rotate(40deg);
      }

      .block__text {
        padding: 15px;
        display: none;
        .label {
          p,
          a,
          li,
          ol {
            color: #000;
            font-family: NexaRegular;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }
    }
  }
}

.page-template-metiers {
  transition: all ease 0.4s;
  .banner-defaut {
    height: 650px;
    min-height: 650px;
    .item-content {
      height: 650px;
    }
  }
  .big-g-banner-defaut {
    height: 420px;
    width: 240px;
  }
  .wavesvg {
    path {
      transition: all ease 0.4s;
    }
  }

  &.active-details {
    color: white;
    background-color: $dark-green;
    transition: all ease 0.4s;

    .wavesvg {
      path {
        fill: $dark-green;
        transition: all ease 0.4s;
      }
    }

    .block-cercle {
      display: none;
      opacity: 0 !important;
      transition: all ease 0.4s;
    }

    .block-item-content {
      opacity: 1 !important;
      transition: all ease 0.4s;
    }
  }
}
