/*
|--------------------
|      HEADER
|--------------------
*/

body {
  &.menu-active {
    overflow: hidden;

    .logo-w {
      display: none;
      opacity: 0;
      transition: all ease 0.6s;
    }

    .logo-c {
      display: block;
      opacity: 1;
      transition: all ease 0.6s;
    }
  }
}

/*
|
| Header
|---------
*/
.link-menu {
  text-transform: uppercase;
  color: $very-dark-grey;
  font-size: 14px;
  font-weight: 400;
}

#blockaccesclient {
  position: fixed;
  top: 0;
  right: 0;
  width: 0%;
  bottom: 0;
  background: $dark-green;
  z-index: 11;
  display: block;
  transition: all ease 0.6s;

  .zone-acces-client {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: fit-content;
    height: fit-content;
    display: flex;
    vertical-align: middle;
    margin: auto;

    .item-accesclient {
      background-color: white;
      border-radius: 80px;

      img {
        padding: 20px 0px;
        transform: scale(0.95);
        transition: all ease 0.4s;
        @include media-breakpoint-down(md) {
          width: 150px;
          padding: 10px 0px;
        }
      }

      &:hover {
        img {
          padding: 20px 0px;
          transform: scale(1);
          transition: all ease 0.4s;
        }
      }

      &:last-child {
        margin-bottom: 0px !important;
      }
    }
  }

  .closebtn {
    height: 32px;
    width: 32px;
    position: absolute;
    left: -16px;
    top: calc(50% - 16px);
    cursor: pointer;
    display: none;
    @include media-breakpoint-down(xs) {
      top: 30px;
      right: 30px;
      left: initial;
    }

    &:before {
      content: "";
      height: 65px;
      width: 65px;
      position: absolute;
      top: -17px;
      left: -15px;
      border-radius: 50%;
      background-color: $red;
    }

    .bar {
      display: block;
      width: 100%;
      height: 2px;
      background-color: white;
      border-radius: 2px;
      transform: translate(0px, 8px) rotate(45deg);
      position: absolute;
      top: 7px;
    }

    .bar2 {
      display: block;
      width: 100%;
      height: 2px;
      background-color: white;
      border-radius: 2px;
      transform: translate(0px, -8px) rotate(-45deg);
      position: absolute;
      left: 0;
      top: 23px;
    }
  }

  &.active {
    width: 30%;
    transition: all ease 0.6s;
    @include media-breakpoint-down(sm) {
      width: 70%;
    }
    @include media-breakpoint-down(xs) {
      width: 100%;
    }
  }
}

#header {
  width: 100%;
  position: absolute;
  z-index: 10;
  .header-container {
    @extend .container;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;

    .item-logo {
      img {
        height: 61px;
      }
    }

    .item-nav {
      display: none;

      .item-menu {
        display: flex;

        .open {
          .nav-drop {
            &:before {
              content: "";
              top: -11px;
              transition: all ease 0.3s;
            }
          }
        }

        li {
          transition: all ease 0.3s;

          &:not(:last-child) {
            margin-right: 30px;
            @include media-breakpoint-down(xl) {
              margin-right: 20px;
            }
          }

          &.menu-item-has-children {
            position: relative;
            margin-right: 45px;
            @media (max-width: 1400px) {
              margin-right: 40px;
            }
            &:after {
              content: "";
              height: 8px;
              width: 13px;
              right: -17px;
              top: 9px;
              display: block;
              position: absolute;
              background-image: url("../img/icon/arr_red.svg");
              transform: rotate(0deg);
              transition: all ease 0.3s;
            }
            &.active {
              &:after {
                content: "";
                transform: rotate(180deg);
                transition: all ease 0.3s;
              }
            }
          }

          .item-link {
            color: white;
            font-family: "Nexa bold";
            font-size: 12px;
            letter-spacing: 3px;
            font-weight: 700;
            position: relative;
            text-decoration: none;
            text-transform: uppercase;
            z-index: 1;
            @media (max-width: 1400px) {
              font-size: 10px;
            }

            &::before {
              content: "";
              position: absolute;
              width: 0px;
              height: 0px;
              top: 7px;
              left: 1px;
              z-index: -1;
              border-radius: 30px;
              pointer-events: none;
              background: $red;
              background-position: center;
              transition: all ease 0.4s;
              opacity: 0;

              &:hover {
                &::before {
                  top: -6px;
                  left: -9px;
                  opacity: 1;
                  width: 30px;
                  height: 30px;
                  transition: all ease 0.4s;
                }
              }
            }

            &.btn {
              transition: all ease 0.3s;

              a {
                &:before,
                &:after {
                  content: none;
                }
              }
            }
            .menu-item {
              .nav-drop {
                display: none;
                position: absolute;
              }
            }
          }
          &.current-menu-item {
            .item-link {
              &::before {
                opacity: 1;
                top: -6px;
                left: -9px;
                height: 30px;
                width: 30px;
                transition: all ease 0.4s;
              }
            }
          }

          &.acces-client {
            cursor: pointer;
            padding: 11px 11px;
            margin-top: -10px;
            position: relative;
            transition: all ease 0.4s;

            &:before {
              content: "";
              height: 30px;
              width: 30px;
              display: block;
              float: left;
              margin-top: -4px;
              margin-right: 5px;
              z-index: 1;
              position: relative;
              background-image: url("../img/icon/user.svg");
            }

            &:after {
              content: "";
              border: 1px solid #fff;
              border-radius: 30px;
              position: absolute;
              padding: 0 0;
              height: 46px;
              width: 190px;
              right: 0;
              top: 0;
              background-color: transparent;
              transition: all ease 0.4s;
              @media (max-width: 1400px) {
                width: 180px;
              }
            }

            .item-link {
              padding: 0 6px 0 2px;
              z-index: 1;
            }

            &:hover {
              .item-link {
                &::before {
                  opacity: 0 !important;
                  display: none !important;
                }
              }

              &:after {
                content: "";
                border: 1px solid $red;
                background-color: $red;
                transition: all ease 0.4s;
              }
            }
          }
        }
      }

      @include media-breakpoint-up(xl) {
        display: flex;
        align-items: center;
        margin-top: 12px;
      }
    }
  }

  .lang-container {
    position: relative;
    display: inline-flex;
    top: 1px;
    @extend .link-menu;
    cursor: pointer;

    ul {
      position: absolute;
      z-index: 1;
      top: 100%;
      left: 0;
      opacity: 0;
      visibility: hidden;
      transform: translateX(10px);
      transition:
        opacity 1.2s $easeSmooth,
        transform 2s $easeSmooth;
    }

    &:hover {
      ul {
        opacity: 1 !important;
        visibility: visible !important;
        transform: translateX(0) !important;
      }
    }
  }

  .btn-menu {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;

    & > .item-burger {
      display: block;
      width: 25px;

      & > span {
        display: block;
        width: 100%;
        height: 2px;
        background-color: $light-grey;
        border-radius: 2px;

        &:nth-child(2) {
          margin: 6px 0;
        }
      }
    }

    @include media-breakpoint-up(xl) {
      display: none;
    }
  }

  &.sticky-menu {
    border-bottom: 1px solid #eee;
    transition: all ease 0.3s;
    background-color: #fff;
    @media (min-width: 1200px) {
      &:after {
        height: 61px;
        margin-top: 4px;
      }
    }

    .logo-c {
      display: block;
      transition: all ease 0.3s;
    }

    .logo-w {
      display: none;
      transition: all ease 0.3s;
    }

    .header-container {
      padding-top: 6px !important;
      padding-bottom: 6px !important;
      transition: all ease 0.3s;

      .item-menu {
        li {
          padding-top: 8px;
          transition: all ease 0.3s;

          .item-link {
            color: $red !important;
          }
        }

        .btn {
          line-height: 9px;
          padding-top: 13px;
          color: $white !important;
          transition: all ease 0.3s;

          .item-link {
            color: $white !important;
            transition: all ease 0.3s;
          }
        }
      }
    }
  }
}

.nav-ssmenu-drop {
  display: none;
  position: absolute;
  top: 78px;
  left: 0;
  right: 0;
  width: 100%;
  background: white;
  padding: 40px 30px 20px 30px;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  z-index: 4;
  @include media-breakpoint-down(lg) {
    display: none !important;
  }
  .nav-drop {
    display: flex;
    justify-content: space-between;
    &:before {
      content: "";
      height: 11px;
      width: 17px;
      right: 0;
      left: 0;
      margin: auto;
      top: 0px;
      display: block;
      position: absolute;
      background-image: url("../img/icon/triangle.svg");
      transition: all ease 0.3s;
    }
    li {
      color: #000;
      font-family: Nexa Bold;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 14px;
      letter-spacing: 3px;
      text-transform: uppercase;
      margin-bottom: 24px;
      margin-right: 0px !important;
      position: relative;
      &:hover {
        a {
          color: $red;
        }
      }
      &:after {
        content: "";
        background-image: url(../img/icon/rounded-menu.svg);
        background-size: cover;
        width: 25px;
        height: 38px;
        display: inline-flex;
        position: absolute;
      }
      @media (max-width: 1400px) {
        font-size: 10px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.mouse_circle {
  position: fixed;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  margin-top: -10px;
  margin-left: -10px;
  font-size: 18px;
  letter-spacing: 0.05em;
  color: #fff;
  transform: translate(0, 0);
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  z-index: 5;
  opacity: 0;
  @include media-breakpoint-down(md) {
    display: none !important;
  }

  &:before {
    content: "";
    opacity: 1;
    width: 75px;
    height: 75px;
    display: block;
    float: left;
    transition: all ease 0.4s;
    background-image: url("../img/icon/arrow-red.svg");
    background-repeat: no-repeat;
  }

  &.moused {
    width: 75px;
    height: 75px;
    margin-top: -35px;
    margin-left: -35px;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
    opacity: 1;

    &:before {
      content: "";
      height: 72px;
      width: 72px;
      transition: all ease 0.4s;
    }
  }

  &.mousenext {
    &:before {
      transition: all ease 0.4s;
      transform: rotate(180deg);
    }
  }
}

/*
|
| Menu
|-------
*/
#mobile-menu {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: $white;
  overflow: auto;
  opacity: 0;
  visibility: hidden;

  .item-container {
    @extend .container;
    //padding-top: 150px;
    //padding-bottom: 50px;

    ul {
      & > li {
        &:not(:last-child) {
          margin-bottom: 10px;

          @include media-breakpoint-down(1400px) {
            margin-right: 8px;
          }
        }

        @include media-breakpoint-down(md) {
          margin-left: 30px;
          margin-top: 8px;
          a {
            font-size: 16px;
          }
        }

        a {
          text-transform: uppercase;
          color: $very-dark-grey;
          font-size: 20px;
          font-weight: 500;
          -webkit-font-smoothing: antialiased;
        }
      }
      .nav-drop {
        padding: 6px 0 0 0;
        li {
          margin-left: 15px;
          a {
            font-size: 16px;
          }
        }
      }
    }
  }

  .item-socials-container {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;

    .item-socials {
      display: flex;
      align-items: center;
      margin-bottom: 25px;

      a {
        font-size: 25px;

        &:not(:last-child) {
          margin-right: 25px;
        }
      }

      @include media-breakpoint-down(xs) {
        margin-bottom: 15px;

        a {
          font-size: 20px;
        }
      }
    }
  }
}
