/*
|--------------------
|      CONTENT
|--------------------
*/

/*
|
| Banner
|---------
*/

.banner {
  height: 80vh;
  color: white;
  text-align: center;
  background-position: center;
  background-size: cover;
  position: relative;

  .item-content {
    height: 80vh;

    h1 {
      font-family: "Mulat Addis";
      font-style: normal;
      font-weight: 400;
      font-size: 112px;
      line-height: 88px;
    }

    .item-text {
      font-family: "NexaRegular";
      font-size: 16px;
      line-height: 24px;
      text-align: center;
    }
  }
}

.wavesvg {
  height: 100px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -1px;
  z-index: 8;

  path {
    stroke: none;
    fill: $light-blue;
  }
}

.wavesvg-inverse {
  top: -1px;
  bottom: initial;
  transform: rotate(180deg);
}

/*
|
| Custom card
|
| Use in news archive page
|--------------
*/
.custom-card {
  position: relative;
  height: 100%;
  display: grid;
  .icon {
    position: relative;
    display: block;
    width: 56px;
    height: 56px;
    position: absolute;
    top: 220px;
    right: 35px;
    z-index: 1;
    background-image: url("../img/icon/eye.svg");
  }

  .wavesvg {
    height: 40px;
    width: 100%;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 222px;
    z-index: 0;
    path {
      stroke: none;
      fill: #fff;
    }
  }
  .custom-card-link {
    display: block;
    border-radius: 24px;
    overflow: hidden;
    background: white;
    .card-img-container {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 260px;
      .card-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 260px;
        transition: transform 2s $easeOutExpo;
        will-change: transform;
      }
    }
    .card-content {
      padding: 20px 30px;
      .date {
        color: #e62a34;
        font-family: NexaRegular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        display: block;
      }
      .title {
        color: #011a24;
        font-family: Mulat Addis;
        font-size: 22px;
        line-height: 28px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.5px;
        display: block;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .content {
        color: #011a24;
        font-family: NexaRegular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        display: block;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }
    }
  }

  /*
  * Hover state
  */
  &:hover {
    .card-img-container {
      .card-img {
        transform: scale(1.05);
      }
    }
  }
}

/*
|
| Pagination
|---------------
*/
.pagination-container {
  display: table;
  margin: auto;
  li {
    display: inline-block;
    padding: 0 3px;
    span,
    a {
      color: #000;
      text-align: center;
      margin: auto;
      transition: all 0.3s ease-out;
      padding: 3px 8px 0 8px;
      font-weight: 800;
      height: 40px;
      width: 40px;
      text-align: center;
      display: grid;
      align-items: center;
      vertical-align: middle;
      border-radius: 50%;

      &:hover {
        background-color: #e2e2e2;
      }
      &.current {
        color: #fff;
        background: #e62a34;
      }
    }
  }
}

/*
|
| CMS
|------
*/
.cms {
  color: #000;
  font-family: NexaRegular;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  span {
    display: inline;
  }

  h1 {
    @extend .title-xxl;
  }

  h2 {
    color: #e62a34;
    font-family: Mulat Addis;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0.64px;
    margin-bottom: 20px;
  }

  img {
    display: block;
    height: auto;
    margin: auto;
  }

  strong {
    font-weight: 800;
  }

  p {
    margin-bottom: 20px;
  }

  ul {
    margin-bottom: 20px;
    padding-left: 20px;

    li {
      list-style: none;
      list-style-type: none;
      &:before {
        content: "";
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #e62a34;
        margin-right: 10px;
      }

      &:not(:last-child) {
        margin-bottom: 5px;
      }

      a {
        display: contents;
      }

      ul {
        li {
          list-style: circle;
        }
      }
    }
  }
  td,
  tr {
    padding: 10px 10px;
  }

  ol {
    margin-bottom: 20px;
    padding-left: 20px;
  }

  &.cms-no-break {
    p {
      margin-bottom: 0;
    }
  }

  blockquote {
    position: relative;

    width: 80%;

    margin-right: 0;
    margin-left: auto;
    padding: 130px 0;

    font-size: 30px;
    line-height: 1.5em;
    font-weight: 300;

    @include media-breakpoint-down(md) {
      margin: auto;
    }

    &:before {
      content: "";

      position: absolute;

      background-image: url("../img/icon/quote-left.svg");

      width: 50px;
      height: 50px;

      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;

      transform: translateX(-60px) translateY(-30px);
    }

    &:after {
      content: "";

      position: absolute;
      right: 0;

      background-image: url("../img/icon/quote-right.svg");

      width: 50px;
      height: 50px;

      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;

      transform: translateX(0) translateY(-50px);

      @include media-breakpoint-down(md) {
        transform: translateX(60px) translateY(-50px);
      }
    }
  }
}

/*
|
| Page offset
|--------------
*/
.page-offset {
  padding-top: $headerHeight;

  @include media-breakpoint-down(lg) {
    padding-top: $headerHeightSm;
  }
}

/*
|
| Custom Loader
|---------------
*/
.custom-loader {
  width: 100px;
  display: inline-block;

  &.cl-center {
    margin-left: auto;
    margin-right: auto;
  }

  svg {
    display: inline-block;
    width: 100%;
    height: auto;
    fill: $black;

    circle {
      stroke: $black;
    }
  }

  &.ajax-loader {
    display: none;
  }
}

.btn {
  height: 180px;
  width: 180px;
  position: relative;
  background: none;
  border: none;
  padding: 0;
  z-index: 8;
  display: flex;
  align-items: center;
  text-align: center;
  vertical-align: middle;

  &:before {
    content: "";
    background-color: $red;
    height: 159px;
    width: 159px;
    display: block;
    border-radius: 50%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    transition: all ease 0.3s;
  }

  &:hover {
    &:before {
      content: "";
      height: 179px;
      width: 179px;
      transition: all ease 0.3s;
    }
  }

  span {
    width: 100%;

    .btn-text {
      font-family: NexaRegular;
      font-size: 12px;
      line-height: 22px;
      padding: 0 25px;
      letter-spacing: 0.23em;
      z-index: 1;
      text-align: center;
      width: 100%;
      color: #ffffff;
      text-transform: uppercase;
    }
  }
}

.down {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 10px;
}

.scrolldown {
  width: 1px;
  height: 115px;
  position: absolute;
  bottom: 150px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 9;
  overflow: hidden;
  background: linear-gradient(to bottom, white 50%, rgba(255, 255, 255, 0) 50%);
  background-position: 0 -115px;
  background-size: 100% 200%;
  animation: scrolldown 1.5s cubic-bezier(0.76, 0, 0.3, 1) forwards infinite;

  &:before {
    content: "";
    left: 0;
    right: 0;
    height: 115px;
    display: block;
    margin: auto;
    opacity: 0.6;
    background-color: #fff;
  }
}

@keyframes scrolldown {
  0% {
    background-position: 0 -115px;
  }
  50% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 115px;
  }
}

.big-g {
  background-image: url("../img/global/big-g.svg");
  background-size: cover;
  z-index: 0;
}

#swiper-slideschronologie {
  .swiper-wrapper {
    height: initial !important;

    .swiper-slide {
      text-align: center;
      color: white;
      position: relative;

      h3 {
        font-family: "Mulat Addis";
        font-style: normal;
        font-weight: 400;
        font-size: 64px;
        line-height: 64px;
        @include media-breakpoint-down(sm) {
          font-size: 36px;
        }
      }

      .desc {
        font-family: "NexaRegular";
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        height: 40px;
        z-index: 12;
        position: relative;
      }

      &:before {
        content: "";
        height: 1px;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: white;
        opacity: 0.4;
      }

      &:after {
        content: "";
        height: 20px;
        width: 20px;
        background-color: $red;
        position: absolute;
        bottom: -9px;
        left: 0;
        right: 0;
        margin: auto;
        border-radius: 20px;
        z-index: 11;
      }

      &.odd {
        margin-top: 150px;

        &:before {
          content: "";
          top: -2px;
          bottom: initial;
        }

        &:after {
          content: "";
          top: -12px;
          bottom: initial;
        }
      }
    }
  }
}

.swiper-buttons {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: -100px;
  z-index: 11;
  text-align: center;
  width: 150px;

  @include media-breakpoint-down(md) {
    display: block;
  }

  .chrono-button-next,
  .chrono-button-prev {
    height: 60px;
    width: 60px;
    float: left;
    margin-left: 15px;
    position: relative;
    cursor: pointer;
    border: 1px solid $red;
    box-sizing: border-box;
    border-radius: 10px;
    transition: all ease 0.3s;

    &:before {
      content: "";
      background-image: url("../img/icon/left-arrow-b.svg");
      background-size: cover;
      width: 22px;
      height: 19px;
      position: absolute;
      top: 20px;
      left: 16px;
    }

    &:hover {
      background-color: $red;
      transition: all ease 0.3s;

      &:before {
        content: "";
        background-image: url("../img/icon/left-arrow-w.svg");
      }
    }
  }

  .chrono-button-prev {
    margin-left: 0px;

    &:before {
      content: "";
      transform: rotate(180deg);
    }
  }
}

.iziModal {
  width: 100%;
  max-width: 1200px !important;
  border-radius: 100px !important;
  background: none !important;
  box-shadow: none !important;

  .iziModal-content {
    background-color: $dark-blue;
    border-radius: 100px;
    color: white;
    padding: 110px !important;
    @include media-breakpoint-down(lg) {
      padding: 50px !important;
    }
    @include media-breakpoint-down(sm) {
      text-align: center;
      border-radius: 30px;
    }
    @include media-breakpoint-down(xs) {
      padding: 15px !important;
    }

    .bg {
      height: 398px;
      background-position: center;
      background-size: cover;
      float: left;
      margin: 0 50px 40px 0;
      display: block;
      width: 31%;
      @include media-breakpoint-down(lg) {
        margin: 0 40px 30px 0;
      }
      @include media-breakpoint-down(md) {
        height: 310px;
        margin: 0 40px 30px 0;
        width: 27%;
      }
      @include media-breakpoint-down(sm) {
        float: initial;
        height: 250px;
        margin: auto;
        width: 195px;
      }
    }

    .info {
      width: 63%;
      float: left;
      @include media-breakpoint-down(sm) {
        width: 100%;
        float: inherit;
      }

      h3 {
        font-family: "Mulat Addis";
        font-style: normal;
        font-weight: 400;
        font-size: 67px;
        line-height: 78px;
        @include media-breakpoint-down(lg) {
          font-size: 56px;
          line-height: 58px;
        }
        @include media-breakpoint-down(md) {
          font-size: 56px;
          line-height: 68px;
          margin-top: 20px;
        }
        @include media-breakpoint-down(xs) {
          font-size: 26px;
          line-height: 38px;
        }
      }

      .post {
        font-family: "NexaRegular";
        font-size: 14px;
        line-height: 24px;
      }

      hr {
        width: 140px;
        height: 1px;
        margin: 27px 0;
        @include media-breakpoint-down(sm) {
          margin: 15px auto;
        }
      }

      .caracteristiques {
        width: 100%;
        height: 213px;
        @include media-breakpoint-down(md) {
          height: initial;
        }

        .carac {
          font-family: "Mulat Addis";
          font-style: normal;
          font-weight: 400;
          font-size: 32px;
          line-height: 40px;
          color: white;
          float: left;
          width: 50%;
          margin-bottom: 25px;
          z-index: 0;
          position: relative;
          @include media-breakpoint-down(lg) {
            font-size: 25px;
            line-height: 37px;
          }
          @include media-breakpoint-down(sm) {
            float: initial;
            text-align: left;
            width: 290px;
            margin: 0 auto 13px auto;
          }

          &:before {
            content: "";
            height: 55px;
            width: 55px;
            display: block;
            border-radius: 50%;
            position: absolute;
            z-index: -1;
            top: -5px;
            background-color: $red;
            @include media-breakpoint-down(lg) {
              height: 35px;
              width: 35px;
              top: 3px;
            }
          }
        }
      }
    }

    .like-dislike {
      width: 100%;
      @include media-breakpoint-down(sm) {
        margin-top: 40px;
      }

      .like,
      .dislike {
        float: left;
        width: 36%;
        @include media-breakpoint-down(md) {
          width: 50%;
        }
        @include media-breakpoint-down(sm) {
          margin: auto;
          width: 290px;
          display: block;
          float: initial;
        }
      }

      .dislike {
        img {
          transform: scaleY(-1);
        }
      }

      .icon {
        height: 70px;
        width: 70px;
        min-height: 70px;
        min-width: 70px;
        border-radius: 50%;
        background: #ff0034;
        margin: 0 15px 0 0;
        float: left;
        @include media-breakpoint-down(sm) {
          height: 35px;
          width: 35px;
          min-height: 35px;
          min-width: 35px;
        }

        img {
          padding: 14px 14px;
          @include media-breakpoint-down(sm) {
            padding: 4px 4px;
            height: 35px;
          }
        }
      }

      h4 {
        font-family: "Mulat Addis";
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 44px;
        float: left;
        padding: 13px 0;
        @include media-breakpoint-down(sm) {
          font-size: 22px;
          line-height: 34px;
          float: left;
          padding: 0;
        }
      }

      .desc {
        font-family: "NexaRegular";
        font-size: 14px;
        line-height: 24px;
        margin-top: 15px;
        margin-right: 15px;
        @include media-breakpoint-down(sm) {
          text-align: left;
          margin: 15px 15px 15px 0px;
        }
      }
    }
  }

  .icon-close2 {
    font-family: "Mulat Addis";
    transform: rotate(45deg);
    color: white;
    line-height: 35px;
    font-size: 50px;
    position: absolute;
    top: 50px;
    right: 50px;
    cursor: pointer;
    @include media-breakpoint-down(sm) {
      top: 5px;
      right: 5px;
    }

    &:hover {
      opacity: 0.7;
    }
  }
}

.banner-defaut {
  height: calc(90vh - 100px);
  min-height: 620px;
  color: white;
  text-align: center;
  background-position: center;
  background-size: cover;
  position: relative;
  margin-bottom: 80px;
  overflow: hidden;

  &:before {
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.4;
    background-color: $dark-green;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
  }

  .item-content {
    height: calc(90vh - 100px);
    min-height: 400px;
    z-index: 2;
    position: relative;

    .suptitle {
      font-family: "NexaRegular";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      text-transform: uppercase;

      b {
        font-weight: normal;
        @include media-breakpoint-down(sm) {
          display: block;
          padding-bottom: 6px;
        }
      }

      span {
        width: 7px;
        height: 7px;
        border-radius: 50%;
        display: inline-block;
        background-color: $red;
        margin: 0 20px;
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }

    h1 {
      font-family: "Mulat Addis";
      font-style: normal;
      font-weight: 400;
      font-size: 112px;
      line-height: 88px;
      @include media-breakpoint-down(lg) {
        font-size: 80px;
        line-height: 58px;
      }
      @include media-breakpoint-down(md) {
        font-size: 60px;
        line-height: 48px;
      }
      @include media-breakpoint-down(sm) {
        font-size: 46px;
        line-height: 48px;
      }
      @include media-breakpoint-down(xs) {
        font-size: 37px;
        line-height: 39px;
      }
    }

    .item-text {
      font-family: "NexaRegular";
      font-size: 16px;
      line-height: 24px;
      text-align: center;
    }
  }
}

.scrolldown-defaut {
  width: 1px;
  height: 115px;
  position: absolute;
  top: calc(90vh - 265px);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 9;
  overflow: hidden;
  background: linear-gradient(to bottom, white 50%, rgba(255, 255, 255, 0) 50%);
  background-position: 0 -115px;
  background-size: 100% 200%;
  animation: scrolldown 1.5s cubic-bezier(0.76, 0, 0.3, 1) forwards infinite;

  &:before {
    content: "";
    left: 0;
    right: 0;
    height: 115px;
    display: block;
    margin: auto;
    opacity: 0.6;
    background-color: #fff;
  }
}

.down-defaut {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  transition: initial;
  top: calc(90vh - 190px);
}

.big-g-banner-defaut {
  height: 600px;
  width: 350px;
  position: absolute;
  right: 50px;
  bottom: -10px;
  opacity: 0.8;
  z-index: 1;
  @include media-breakpoint-down(sm) {
    opacity: 0.5;
    right: 0px;
    bottom: 0px;
    height: 390px;
    width: 226px;
  }
  @include media-breakpoint-down(xs) {
    opacity: 0.2;
  }
}

.back {
  font-family: "NexaRegular";
  color: white;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.4em;
  position: relative;
  text-transform: uppercase;
  display: flex;
  vertical-align: middle;
  align-items: center;
  cursor: pointer;

  &:before {
    content: "";
    opacity: 1;
    height: 72px;
    width: 72px;
    display: block;
    float: left;
    margin-right: 20px;
    transition: all ease 0.4s;
    background-image: url("../img/icon/arrow-red.svg");
  }

  &:hover {
    &:before {
      opacity: 0.8;
      margin-right: 15px;
      transition: all ease 0.4s;
    }
  }
}

.nocontent {
  text-align: center;
  margin: auto;
}
.block-more {
  background: #f8fbff;
}
