/*
|--------------------
|     PAGE HOME
|--------------------
*/

.banner-home {
  height: calc(100vh - 100px);
  color: white;
  text-align: center;
  background-position: center;
  background-size: cover;
  position: relative;
  margin-bottom: 80px;
  overflow: hidden;
  min-height: 620px;
  &:before {
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.5;
    background-color: $dark-green;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
  }

  .big-g-banner {
    height: 600px;
    width: 350px;
    position: absolute;
    right: -40px;
    bottom: 0;
    opacity: 0.8;
    z-index: 1;
    @include media-breakpoint-down(md) {
      height: 490px;
      width: 280px;
      opacity: 0.5;
    }
    @include media-breakpoint-down(sm) {
      height: 380px;
      width: 220px;
      opacity: 0.3;
    }
  }

  .item-content {
    height: calc(100vh - 100px);
    min-height: 620px;
    z-index: 2;
    position: relative;

    .suptitle {
      font-family: "NexaRegular";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.16em;
      text-transform: uppercase;

      b {
        font-weight: normal;
        @include media-breakpoint-down(sm) {
          display: block;
          padding-bottom: 6px;
        }
      }

      span {
        width: 7px;
        height: 7px;
        border-radius: 50%;
        display: inline-block;
        background-color: $red;
        margin: 0 20px;
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }

    h1 {
      font-family: "Mulat Addis";
      font-style: normal;
      font-weight: 400;
      font-size: 100px;
      line-height: 88px;
      @include media-breakpoint-down(lg) {
        font-size: 80px;
        line-height: 58px;
      }
      @include media-breakpoint-down(md) {
        font-size: 60px;
        line-height: 48px;
      }
      @include media-breakpoint-down(sm) {
        font-size: 46px;
        line-height: 48px;
      }
      @include media-breakpoint-down(xs) {
        font-size: 37px;
        line-height: 39px;
      }
    }

    .item-text {
      font-family: "NexaRegular";
      font-size: 16px;
      line-height: 24px;
      text-align: center;
    }
  }

  .video-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 0;

    .bg-video {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .btn-vid {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      .btn-video {
        cursor: pointer;
        z-index: 1;
        position: absolute;
        bottom: 20px;
        left: 15px;
        height: 32px;
        width: 32px;
        transition: all ease 0.3s;
        opacity: 1;

        &:hover {
          transition: all ease 0.3s;
          opacity: 0.6;
        }

        //.play {
        //  background-size: cover;
        //  background-image: url('../img/icon/play.svg');
        //  height: 32px;
        //  width: 32px;
        //}

        //.pause {
        //  background-size: cover;
        //  background-image: url('../img/icon/pause.svg');
        //  height: 32px;
        //  width: 32px;
        //}
      }
    }
  }
}

#section3 {
  .block-btn {
    position: relative;
    @include media-breakpoint-down(md) {
      top: 0;
    }

    .btn {
      @include media-breakpoint-down(md) {
        margin: auto;
      }
    }
  }
}

#section6 {
  @include media-breakpoint-down(md) {
    .block-text-img {
      padding-bottom: 0px;
    }
  }
}
