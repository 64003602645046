/*
|--------------------
|     PAGE CABINETS
|--------------------
*/

.page-template-cabinets {
  .banner-cabinet {
    height: calc(100vh - 100px);
    color: white;
    background-position: center;
    background-size: cover;
    position: relative;
    margin-bottom: 80px;
    overflow: hidden;
    min-height: 620px;
    &:before {
      content: "";
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0.5;
      background-color: $dark-green;
      width: 100%;
      height: 100%;
      z-index: 1;
      position: absolute;
    }

    .text {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: white;
    }

    .big-g-banner {
      height: 600px;
      width: 350px;
      position: absolute;
      right: -40px;
      bottom: 0;
      opacity: 0.8;
      z-index: 1;
      @include media-breakpoint-down(md) {
        height: 490px;
        width: 280px;
        opacity: 0.5;
      }
      @include media-breakpoint-down(sm) {
        height: 380px;
        width: 220px;
        opacity: 0.3;
      }
    }

    .item-content {
      height: calc(100vh - 100px);
      min-height: 620px;
      z-index: 2;
      position: relative;

      .suptitle {
        font-family: "NexaRegular";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0.16em;
        text-transform: uppercase;

        b {
          font-weight: normal;
          @include media-breakpoint-down(sm) {
            display: block;
            padding-bottom: 6px;
          }
        }

        span {
          width: 7px;
          height: 7px;
          border-radius: 50%;
          display: inline-block;
          background-color: $red;
          margin: 0 20px;
          @include media-breakpoint-down(sm) {
            display: none;
          }
        }
      }

      h1 {
        font-family: "Mulat Addis";
        font-style: normal;
        font-weight: 400;
        font-size: 100px;
        line-height: 88px;
        @include media-breakpoint-down(lg) {
          font-size: 80px;
          line-height: 58px;
        }
        @include media-breakpoint-down(md) {
          font-size: 60px;
          line-height: 48px;
        }
        @include media-breakpoint-down(sm) {
          font-size: 46px;
          line-height: 48px;
        }
        @include media-breakpoint-down(xs) {
          font-size: 37px;
          line-height: 39px;
        }
      }

      .item-text {
        font-family: "NexaRegular";
        font-size: 16px;
        line-height: 24px;
        text-align: center;
      }
    }

    .video-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: 0;

      .bg-video {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .btn-vid {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;

        .btn-video {
          cursor: pointer;
          z-index: 1;
          position: absolute;
          bottom: 20px;
          left: 15px;
          height: 32px;
          width: 32px;
          transition: all ease 0.3s;
          opacity: 1;

          &:hover {
            transition: all ease 0.3s;
            opacity: 0.6;
          }

          //.play {
          //  background-size: cover;
          //  background-image: url('../img/icon/play.svg');
          //  height: 32px;
          //  width: 32px;
          //}

          //.pause {
          //  background-size: cover;
          //  background-image: url('../img/icon/pause.svg');
          //  height: 32px;
          //  width: 32px;
          //}
        }
      }
    }
  }

  .big-g-banner {
    @include media-breakpoint-down(md) {
      height: 530px;
      width: 330px;
      right: -50px;
    }
  }

  .text-team,
  .text-team p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  #section1 {
    z-index: 9;
    position: relative;
  }

  .city {
    font-family: "Mulat Addis";
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 64px;
    color: white;
    @include media-breakpoint-down(sm) {
      font-size: 24px;
      line-height: 30px;
    }
  }

  .adress {
    font-family: "NexaRegular";
    font-size: 14px;
    line-height: 24px;
    color: white;
  }

  .scrolldown {
    height: 40%;
    overflow: hidden;
    background-position: 0 500px;
    animation: scrolldown 1.5s cubic-bezier(0.76, 0, 0.3, 1) forwards infinite;
    @include media-breakpoint-down(sm) {
      height: 10%;
    }

    &:before {
      content: "";
      height: 500px;
    }

    &:after {
      content: "";
      height: 40%;
      overflow: hidden;
    }
  }

  @keyframes scrolldown {
    0% {
      background-position: 0 100%;
    }
    50% {
      background-position: 0 0;
    }
    100% {
      background-position: 0 -100%;
    }
  }
}
