/*
|--------------------
|     PAGE Metiers
|--------------------
*/

#page-nousrejoindre {
  position: relative;

  .block-double-slider {
    #swiper-rejoindre {
      position: relative;
      border-radius: 50%;
      height: 630px;
      width: 100%;
      @include media-breakpoint-down(xl) {
        height: 540px;
        width: 540px;
      }
      @include media-breakpoint-down(lg) {
        height: 450px;
        width: 450px;
      }
      @include media-breakpoint-down(sm) {
        height: 270px;
        width: 270px;
      }

      .swiper-slide {
        border-radius: 50%;

        .elem-bg {
          height: 630px;
          width: 100%;
          background-size: cover;
          background-position: center;
          @include media-breakpoint-down(xl) {
            height: 540px;
            width: 540px;
          }
          @include media-breakpoint-down(lg) {
            height: 450px;
            width: 450px;
          }
          @include media-breakpoint-down(sm) {
            height: 270px;
            width: 270px;
          }
        }
      }
    }

    .rejoindre-buttons {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      bottom: -30px;
      z-index: 11;
      text-align: center;
      width: 180px;
      display: flex;

      .rejoindre-button-next,
      .rejoindre-button-prev {
        cursor: pointer;
        opacity: 1;
        height: 72px;
        width: 72px;
        display: block;
        float: left;
        margin: 0 9px;
        transition: all ease 0.4s;
        background-image: url("../img/icon/arrow-red.svg");
        &:focus {
          border-radius: 50%;
        }
      }

      .rejoindre-button-prev {
        float: left;
      }

      .rejoindre-button-next {
        transform: rotate(180deg);
      }
    }

    #swiper-address {
      width: 100%;
      overflow: hidden;
      @include media-breakpoint-down(md) {
        text-align: center;
        max-width: 100%;
      }
      .swiper-wrapper {
        @include media-breakpoint-down(md) {
          max-width: 380px;
        }
        .swiper-slide {
          opacity: 0.3;
          transition: all ease 0.4s;
          &.swiper-slide-active {
            &.swiper-slide-visible {
              opacity: 1;
              transition: all ease 0.4s;
            }
          }

          &.swiper-slide-next {
            &.swiper-slide-visible {
              opacity: 1;
              transition: all ease 0.4s;
            }
          }

          h2 {
            font-size: 48px;
            line-height: 48px;
            @include media-breakpoint-down(xl) {
              font-size: 40px;
              line-height: 40px;
            }
            @include media-breakpoint-down(lg) {
              font-size: 28px;
              line-height: 28px;
            }
          }
        }
      }
    }
  }
}
