/*
|-----------------------
| 	  Gravity Form
|-----------------------
|
*/

/*
|
| Reset form elements
|-------------------------
*/
input, textarea, select{
    width: 100%;

    -webkit-appearance: none;

    border: none;
    border: {
        bottom: 1px solid $black;
    }
    border-radius: 0;

    &:focus{
        outline: none;
    }
}

select{
    background-image: url('../img/icon/bottom.svg');
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: calc(100% - 10px) center;
}

.validation_error{
    margin-bottom: 30px;
    color: #B00!important;
}

.validation_message{
    margin-top: 5px;
    color: #B00;
}

#gform_ajax_spinner {
    //display: none;
    margin-left: 10px;
    display: inline-block;
    width: 50px;
    height: 50px;
}

/*
|
| Gravity form bootstrap
|-------------------------
*/
.gravity-form-bootstrap{
    .gform_fields{
        @extend .row;
    }
}

/*
|
| Materialize form
|-------------------
*/
.materialize-form{
    .materialize-form-group{
        position: relative;
        margin-bottom: 25px;

        label{
            position: absolute;

            top: 0;
            left: 15px;
        }
        input{
            margin-bottom: 35px;

            padding: 7px 0;
        }

        &.gfield_error{
            label{
                top: -20px;
            }
        }
    }
}