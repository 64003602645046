/*
|--------------------
|     PAGE CLIENTS
|--------------------
*/

.page-template-clients {
  .banner-clients {
    height: calc(90vh - 100px);
    color: white;
    background-position: center;
    background-size: cover;
    position: relative;
    margin-bottom: 80px;
    overflow: hidden;
    min-height: 580px;
    @include media-breakpoint-down(md) {
      height: initial;
    }

    &:before {
      content: "";
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0.4;
      background-color: $dark-green;
      width: 100%;
      height: 100%;
      z-index: 1;
      position: absolute;
    }

    .item-content {
      height: calc(90vh - 100px);
      z-index: 2;
      position: relative;
      display: flex;
      vertical-align: middle;
      align-items: center;
      @include media-breakpoint-down(md) {
        height: initial;
        margin-top: 140px;
        margin-bottom: 90px;
      }

      h1 {
        font-family: "Mulat Addis";
        font-style: normal;
        font-weight: 400;
        font-size: 100px;
        line-height: 88px;
        @include media-breakpoint-down(lg) {
          font-size: 80px;
          line-height: 58px;
        }
        @include media-breakpoint-down(md) {
          font-size: 60px;
          line-height: 48px;
        }
        @include media-breakpoint-down(sm) {
          font-size: 46px;
          line-height: 48px;
        }
        @include media-breakpoint-down(xs) {
          font-size: 37px;
          line-height: 39px;
        }
      }

      .intro {
        font-family: "NexaRegular";
        font-size: 16px;
        line-height: 24px;
      }

      .piliers {
        li {
          display: flex;
          padding: 15px 10px 15px 0;

          &:not(:last-child) {
            border-bottom: 1px solid white;
          }

          .icon {
            height: 70px;
            width: 70px;
            min-height: 70px;
            min-width: 70px;
            border-radius: 50%;
            background: #ff0034;
            margin: 5px 10px 5px 0;

            img {
              padding: 16px;
              display: block;
              stroke: white;
            }
          }

          .text {
            font-family: "Mulat Addis";
            font-style: normal;
            font-weight: 400;
            font-size: 30px;
            line-height: 31px;
            display: flex;
            vertical-align: middle;
            align-items: center;
            @include media-breakpoint-down(lg) {
              font-size: 26px;
              line-height: 28px;
            }
            @include media-breakpoint-down(sm) {
              font-size: 20px;
              line-height: 24px;
            }
          }
        }
      }
    }
  }

  .block-accompagnement {
    h2 {
      z-index: 1;
      position: relative;
    }
    .intro {
      color: #292929;
      font-family: NexaRegular;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
    .accomp-block {
      position: relative;
      background-color: white;
      padding: 72px;
      border-radius: 80px;
      z-index: 0;
      @include media-breakpoint-down(sm) {
        padding: 10px 30px;
        border-radius: 30px;
        margin-top: 20px;
      }
      @include media-breakpoint-down(xs) {
        padding: 10px 15px;
      }
      .text {
        color: #5b5b5b;
        font-family: NexaRegular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }

    .accomp-select {
      display: block;
      margin: 30px 0;
      padding-bottom: 2px;
      min-width: 180px;
      z-index: 1;
      position: relative;
      width: 350px;
      @include media-breakpoint-down(sm) {
        width: 100%;
      }

      &.active {
        button {
          &:after {
            transform: rotate(0deg);
            transition: all ease 0.4s;
          }
        }
      }

      label {
        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }

      button {
        background: #fff;
        color: black;
        line-height: 48px;
        position: relative;
        text-shadow: none;
        z-index: 1;
        outline: none;
        overflow: hidden;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 7px 14px 3px 14px;
        width: 350px;
        border: 1px solid $red;
        box-sizing: border-box;
        border-radius: 120px;
        display: block;
        font-family: "Nexa Bold";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        text-align: center;
        letter-spacing: 3px;
        text-transform: uppercase;
        transition: all ease 0.4s;
        cursor: pointer;
        @include media-breakpoint-down(sm) {
          width: 100%;
          line-height: 22px;
          padding: 15px 0;
        }

        &:focus,
        &:hover {
          background: $red;
          color: white;
          transition: all ease 0.4s;

          &:after {
            content: "";
            transition: all ease 0.4s;
            background-image: url("../img/icon/accordion.svg");
          }
        }

        &:after {
          content: "";
          height: 9px;
          width: 16px;
          margin-left: 20px;
          display: inline-block;
          transition: all ease 0.4s;
          background-image: url("../img/icon/accordion-red.svg");
          transform: rotate(180deg);
        }

        &.active {
          background: $red;
          color: white;
          transition: all ease 0.4s;

          &:after {
            content: "";
            transition: all ease 0.4s;
            background-image: url("../img/icon/accordion.svg");
          }
        }
      }

      .ul-list {
        width: 100%;
        background-color: white;
        list-style: none;
        line-height: 26px;
        overflow: hidden;
        margin: 0;
        max-height: 0;
        position: absolute;
        top: 60px;
        padding: 0;
        cursor: pointer;
        transition: all 0.15s cubic-bezier(0.35, 0, 0.25, 1);
        box-shadow:
          0 1px 3px rgba(0, 0, 0, 0.12),
          0 1px 2px rgba(0, 0, 0, 0.24) !important;

        li {
          margin: 0;
          padding: 13px 16px 10px 16px;
          outline: none;
          overflow: hidden;

          &:focus,
          &:hover,
          &.active {
            background: rgba(0, 0, 0, 0.1);
          }

          &.active {
          }
        }
      }

      &.active ul {
        max-height: 200px;
        overflow: auto;
        z-index: 2;
        transition: all 0.2s ease;
      }
    }

    .accomp-text {
      .text {
        display: none;
      }
    }
  }

  .block-etudes-de-cas {
    color: white;
    position: relative;
    background-color: $dark-green;
    min-height: 700px;

    .wavesvg-inverse {
      // top: initial;
    }

    h2 {
      color: white;
      @include media-breakpoint-down(xl) {
        font-size: 80px;
        line-height: 85px;
      }
      @include media-breakpoint-down(lg) {
        font-size: 60px;
        line-height: 65px;
      }
      @include media-breakpoint-down(md) {
        font-size: 40px;
        line-height: 50px;
      }
    }

    .item-client {
      @include media-breakpoint-up(lg) {
        display: flex !important;
        vertical-align: middle;
        align-items: center;
      }

      .block-details {
        float: left;
        width: 80%;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        @include media-breakpoint-down(md) {
          float: initial;
          width: 100%;
        }

        .details {
          display: flex !important;
          vertical-align: middle;
          align-items: flex-start;
          @include media-breakpoint-down(lg) {
            img {
              width: 60px;
            }
          }
          @include media-breakpoint-down(md) {
            margin-bottom: 15px;
            img {
              width: 70px;
              position: absolute;
              opacity: 0.6;
            }
          }
        }
      }

      .client {
        padding-bottom: 40px;
        width: 30%;
        float: left;
        max-width: 260px;
        position: relative;
        @include media-breakpoint-down(md) {
          padding-bottom: 0px;
          width: 100%;
          float: initial;
        }

        &:after {
          content: "";
          background-image: url("../img/icon/rounded.svg");
          width: 82px;
          height: 128px;
          display: block;
          position: absolute;
          bottom: 0;
          right: 0;
          @include media-breakpoint-down(md) {
            content: initial;
          }
        }

        &.no-img {
          &:before {
            content: "";
            background-image: url("../img/icon/rounded.svg");
            width: 82px;
            height: 128px;
            display: block;
            position: absolute;
            bottom: 50px;
            left: 0;
            transform: scaleY(-1) scaleX(1) rotate(90deg);
            @include media-breakpoint-down(md) {
              content: initial;
            }
          }
        }

        .block-img {
          width: 163px;
          height: 209px;
          border-radius: 170px;
          display: block;
          overflow: hidden;
          margin-bottom: 20px;
          @include media-breakpoint-down(lg) {
            width: 163px;
            height: 209px;
          }
          @include media-breakpoint-down(md) {
            width: 70px;
            height: 70px;
            float: left;
            margin-right: 20px;
          }

          .bg {
            width: 163px;
            height: 209px;
            border-radius: 170px;
            background-position: center;
            background-size: cover;
            position: relative;
            @include media-breakpoint-down(md) {
              width: 70px;
              height: 70px;
            }

            &.photo1 {
              display: block;
            }
          }
        }

        h3 {
          font-family: "Mulat Addis";
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 31px;
          margin-left: 20px;
          color: white;
        }

        .post {
          font-family: "NexaRegular";
          font-size: 14px;
          line-height: 24px;
          color: white;
          padding-top: 6px;
          margin-left: 20px;
          display: block;
        }
      }
    }

    hr {
      opacity: 0.4;
      height: 1px;
      width: 100%;
      border: none;
      background-color: white;
    }

    .item-sup {
      .carac {
        font-family: "Mulat Addis";
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 40px;
        color: white;
        z-index: 0;
        position: relative;
        @include media-breakpoint-down(lg) {
          font-size: 25px;
          line-height: 37px;
        }
        @include media-breakpoint-down(sm) {
          float: initial;
          text-align: left;
          margin: 0 auto 13px auto;
        }

        &:before {
          content: "";
          height: 55px;
          width: 55px;
          display: block;
          border-radius: 50%;
          position: absolute;
          z-index: -1;
          top: -5px;
          background-color: $red;
          @include media-breakpoint-down(lg) {
            height: 35px;
            width: 35px;
            top: 3px;
          }
        }
      }
    }

    .edc-buttons {
      width: 100%;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      height: auto;

      .edc-button-prev {
        width: 50%;
        display: block;
        height: auto;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
      }

      .edc-button-next {
        width: 50%;
        display: block;
        height: auto;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
      }

      @include media-breakpoint-down(md) {
        &.btn-top {
          bottom: inherit;
          top: 50px;
          margin: auto;
          height: 50px;
          width: 130px;
          z-index: 11;

          .edc-button-prev {
            content: "";
            opacity: 1;
            width: 55px;
            height: 55px;
            display: block;
            float: left;
            transition: all ease 0.4s;
            background-size: contain;
            background-image: url("../img/icon/arrow-red.svg");
          }

          .edc-button-next {
            content: "";
            opacity: 1;
            width: 55px;
            height: 55px;
            display: block;
            float: left;
            transition: all ease 0.4s;
            background-size: contain;
            transform: rotate(180deg);
            background-image: url("../img/icon/arrow-red.svg");
          }
        }
        &.btn-bottom {
          top: inherit;
          bottom: 50px;
          margin: auto;
          height: 50px;
          width: 130px;
          z-index: 11;

          .edc-button-prev {
            content: "";
            opacity: 1;
            width: 55px;
            height: 55px;
            display: block;
            float: left;
            transition: all ease 0.4s;
            background-size: contain;
            background-image: url("../img/icon/arrow-red.svg");
          }

          .edc-button-next {
            content: "";
            opacity: 1;
            width: 55px;
            height: 55px;
            display: block;
            float: left;
            transition: all ease 0.4s;
            background-size: contain;
            transform: rotate(180deg);
            background-image: url("../img/icon/arrow-red.svg");
          }
        }
      }
    }
  }

  .block-text-clients {
    h2 {
      color: $dark-green;
      z-index: 1;
      position: relative;
      padding-top: 20px;
    }

    .text {
      color: $dark-green;
      z-index: 1;
      position: relative;
    }

    .item-zone-img {
      z-index: 11;
      position: relative;

      .zone-img {
        background-color: white;
        border-radius: 90px;
        @include media-breakpoint-down(sm) {
          display: inline-block;
        }

        img {
          width: 100%;
          max-width: 200px;
          padding: 40px 10px;
          transition: all ease 0.4s;
          @include media-breakpoint-down(sm) {
            padding: 20px 5px;
          }
        }

        .spec {
          cursor: default;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          color: white;
          background-color: $dark-green;
          border-radius: 90px;
          padding: 15px;
          font-family: "Mulat Addis";
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 27px;
          opacity: 0;
          transition: all ease 0.4s;
        }
      }

      &:hover {
        .spec {
          opacity: 1;
          transition: all ease 0.4s;
        }
      }
    }
  }
}
