/*
|--------------------
|       404
|--------------------
*/

#page-404 {
    background-size: cover;
    background-position: center;
    &:before{
        content:"";
        background-color: black;
        opacity: 0.6;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 0;
    }
    .section-content {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
        color: $white;
        text-align: center;
        z-index: 1;
        position: relative;

        .item-title {
            font-size: 30px;
            font-weight: 300;
            line-height: 1;
        }
        .item-text {
            font-size: 18px;
            font-weight: 300;
            line-height: 1;
            a{
                color: $white;
                text-decoration: underline;
            }
        }
    }
}
