/*
|--------------------
|      NEWS
|--------------------
*/

.load-more-container {
    min-height: 100px;

    display: flex;
    align-items: center;
    justify-content: center;

    .loader-container {
        display: none;
    }
}

#page-news-category {
    background-color: #f8fbff;
    .banner-defaut {
        margin-bottom: 50px;
        min-height: initial;
        height: initial;
        .item-content {
            height: initial;
        }
        .big-g {
            display: none;
        }
    }
    .title-cat {
        margin-bottom: 5px !important;
        h2 {
            font-family: Mulat Addis;
            font-size: 40px;
            font-style: normal;
            font-weight: 400;
            line-height: 44.4px;
            letter-spacing: 0.8px;
            text-align: center;
        }
    }
    .menu-cat {
        position: relative;
        margin: 10px auto;
        display: table;
        text-align: center;
        li {
            display: inline-block;
            margin-bottom: 15px;
            @include media-breakpoint-down(md) {
                margin-bottom: 5px;
            }
            &:not(:last-child) {
                margin-right: 48px;
                @include media-breakpoint-down(md) {
                    margin-right: 15px;
                }
            }
            a {
                color: #5b5b5b;
                font-family: Nexa Bold;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: 14px;
                letter-spacing: 3px;
                text-transform: uppercase;
                transition: all ease 0.3s;
                border-bottom: 1px solid #fff;
                &.active,
                &:hover {
                    border-bottom: 1px solid #e62a34;
                    color: #e62a34;
                    transition: all ease 0.3s;
                }
            }
        }
    }
    .card-cat {
        margin-bottom: 0 !important;
    }
}

.title-red {
    color: #e62a34;
    text-align: center;
    font-family: Mulat Addis;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 44.4px;
    letter-spacing: 0.8px;
    text-align: center;
}
