/*
|--------------------
|      SINGLE
|--------------------
*/

.post-date {
    font-size: 12px;
    color: rgba($grey, 0.6);
    text-transform: uppercase;
    font-weight: 400;
}

.post-excerpt {
    font-size: 22px;
    color: rgba($very-dark-grey, 0.6);
    font-weight: 400;
}

.single {
    background-color: #fff;
    header {
        .logo-w {
            display: none;
        }
        .logo-c {
            display: block;
        }
        .item-menu {
            a {
                color: #141414 !important;
            }
            .acces-client {
                &:not(:hover) {
                    &:after {
                        content: "";
                        border: 1px solid #000 !important;
                    }
                    &:before {
                        content: "";
                        background-image: url("../img/icon/user-b.svg") !important;
                    }
                }
                &:hover {
                    .item-link {
                        color: #fff !important;
                    }
                }
            }
        }
    }
    .header-single {
        display: flex;
        align-items: center;
        vertical-align: middle;
        position: relative;
        .image {
            float: left;
        }
        .bg {
            height: 600px;
            width: 600px;
            margin-left: -150px;
            border-radius: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            @include media-breakpoint-down(md) {
                height: 300px;
                width: 100%;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                margin: 0;
                border-radius: inherit;
            }
        }
        .infos {
            width: 70%;
            float: left;
            padding-left: 7%;
            padding-right: 5%;
            @include media-breakpoint-down(md) {
                padding: 0;
                width: 70%;
            }
        }
        .title {
            color: #e62a34;
            font-family: Mulat Addis;
            font-size: 54px;
            font-style: normal;
            font-weight: 400;
            line-height: 58px;
            letter-spacing: 1.92px;
            @include media-breakpoint-down(lg) {
                font-size: 40px;
                line-height: 46px;
            }
            @include media-breakpoint-down(md) {
                font-size: 28px;
                line-height: 32px;
            }
        }
    }
    .cms {
        img {
            max-width: 100%;
        }
    }
}
