.block-text-img {
  padding-bottom: 80px;
  @include media-breakpoint-down(md) {
    text-align: center;
  }

  .text {
    font-family: "NexaRegular";
    font-size: 16px;
    line-height: 24px;
  }

  .zone-img {
    @include media-breakpoint-down(md) {
      width: 100%;
      display: block;
      margin: auto;
    }

    .block-img {
      height: 640px;
      width: 460px;
      display: block;
      overflow: hidden;
      border-radius: 300px;
      position: relative;
      float: right;
      @include media-breakpoint-down(md) {
        height: 320px;
        width: 100%;
        border-radius: 30px;
      }

      .bg {
        background-position: center;
        background-size: cover;
        height: 640px;
        width: 460px;
        border-radius: 300px;
        transform: scale(1);
        transition: all ease 0.5s;
        float: right;
        @include media-breakpoint-down(md) {
          height: 320px;
          width: 100%;
          border-radius: 30px;
        }
      }

      &:hover {
        .bg {
          transform: scale(1.1);
          transition: all ease 0.5s;
        }
      }
    }

    .block-btn {
      position: absolute;
      top: 0;
      z-index: 1;
      @include media-breakpoint-down(md) {
        position: absolute;
        top: 70px;
        left: 0;
        right: 0;
        margin: auto;
        display: inline-table;
      }
    }
  }

  .block-big-g {
    position: absolute;
    bottom: -108px;
    right: 7px;
    height: 260px;
    width: 149px;
    @include media-breakpoint-down(xl) {
      right: 7px;
    }
    @include media-breakpoint-down(md) {
      right: 16px;
      height: 130px;
      width: 74px;
      bottom: -28px;
    }
  }
}

.block-accomp {
  .d-flex {
    display: flex !important;
    vertical-align: middle;
    align-items: center;
    @include media-breakpoint-down(md) {
      display: block !important;
      vertical-align: initial;
      align-items: initial;
    }
  }
  .item {
    height: 450px;
    width: 100%;
    border: 1px solid #011a24;
    border-radius: 200px;
    padding: 30px;
    text-align: center;
    transition: all ease 0.4s;
    overflow: hidden;
    @include media-breakpoint-down(lg) {
      height: 400px;
      border-radius: 100px;
    }
    @include media-breakpoint-down(md) {
      height: auto;
      border-radius: 30px;
    }

    .icon {
      height: 70px;
      width: 70px;
      border-radius: 50%;
      background: $red;
      margin: 30px auto 30px auto;
      @include media-breakpoint-down(md) {
        margin: 5px auto 20px auto;
      }

      img {
        padding: 20px;
        display: block;
        stroke: white;
      }
    }

    h2 {
      font-family: "Mulat Addis";
      font-style: normal;
      font-weight: 400;
      font-size: 40px;
      line-height: 44px;
      padding-bottom: 16px;
      @include media-breakpoint-down(xl) {
        font-size: 36px;
      }
    }

    .text {
      font-family: "NexaRegular";
      font-size: 14px;
      line-height: 20px;
    }

    &:hover {
      transition: all ease 0.4s;
      background-color: #011a24;

      h2,
      .text {
        color: white;
      }
    }
  }
}

.block-nos-metiers {
  background-size: cover;
  background-position: center;
  position: relative;
  min-height: 700px;
  padding-top: 140px;
  margin-top: 340px;
  background-color: #011a24;
  @include media-breakpoint-down(md) {
    text-align: center;
  }

  &:before {
    content: "";
    background-color: $dark-green;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
    width: 100%;
    opacity: 0.9;
  }
  .d-flex {
    display: flex !important;
    vertical-align: middle;
    align-items: center;
    @include media-breakpoint-down(md) {
      display: block !important;
      vertical-align: initial;
      align-items: initial;
    }
  }
  .btn {
    @include media-breakpoint-down(md) {
      margin: auto;
    }
  }
  h2 {
    color: #ffffff;
    z-index: 1;
    position: relative;
  }

  .text-item {
    color: #ffffff;
    z-index: 1;
    position: relative;
    margin-left: 100px;
    @include media-breakpoint-down(md) {
      margin-left: 0;
    }
  }

  .block-img {
    height: 640px;
    width: 460px;
    display: block;
    overflow: hidden;
    border-radius: 300px;
    position: relative;
    margin-top: -310px;
    float: right;
    z-index: 8;
    @include media-breakpoint-down(xl) {
      float: initial;
      margin: -310px auto 40px auto;
    }
    @include media-breakpoint-down(md) {
      height: 320px;
      width: 100%;
      border-radius: 30px;
    }

    .bg {
      background-position: center;
      background-size: cover;
      height: 640px;
      width: 460px;
      border-radius: 300px;
      transform: scale(1);
      transition: all ease 0.5s;
      float: right;
      @include media-breakpoint-down(md) {
        height: 320px;
        width: 100%;
        border-radius: 30px;
      }
    }
  }

  .list {
    display: flex;
    width: 100%;
    justify-content: space-between;
    @include media-breakpoint-down(xl) {
      display: contents;
      justify-content: initial;
    }
    .item-zone-icon {
      border-radius: 120px;
      border: 1px solid #fff;
      height: 260px;
      width: 160px;
      margin: auto;
      @include media-breakpoint-down(xl) {
        width: 140px;
      }
      @include media-breakpoint-down(lg) {
        margin: auto;
        height: initial;
        border-radius: 20px;
        border-radius: 0;
        border: none;
      }
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
      .zone-img {
        @include media-breakpoint-down(lg) {
          display: flex;
          vertical-align: middle;
          align-items: center;
          border: 1px solid #fff;
          border-radius: 20px;
        }
        .icon {
          width: 72px;
          height: 72px;
          display: flex;
          vertical-align: middle;
          align-items: center;
          margin: 30px auto;
          border-radius: 50px;
          background-color: #e62a34;
          @include media-breakpoint-down(lg) {
            margin: 15px;
          }
          @include media-breakpoint-down(sm) {
            width: 50px;
            height: 50px;
          }
          img {
            width: 100%;
            max-width: 38px;
            max-height: 38px;
            margin: auto;
          }
        }
        .text {
          color: #fff;
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 1.4px;
          text-transform: uppercase;
          @include media-breakpoint-down(xl) {
            font-size: 12px;
            text-align: left;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .wavesvg {
      display: none;
    }
  }
}

.block-client {
  background-size: cover;
  background-position: center;
  background-position: center;
  position: relative;
  min-height: 700px;
  @include media-breakpoint-down(md) {
    text-align: center;
  }

  &:before {
    content: "";
    background-color: $dark-green;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
    width: 100%;
    opacity: 0.9;
  }

  h2 {
    color: #ffffff;
    z-index: 1;
    position: relative;
    padding-top: 20px;
  }

  .text {
    color: #ffffff;
    z-index: 1;
    position: relative;
  }

  .block-btn {
    position: absolute;
    bottom: -220px;
    left: 0;
    z-index: 11;
    @include media-breakpoint-down(md) {
      position: relative;
      bottom: inherit;
    }
    @include media-breakpoint-down(md) {
      .btn {
        margin: 20px auto 0 auto;
      }
    }
  }

  .item-zone-img {
    z-index: 11;
    position: relative;

    .zone-img {
      img {
        background-color: white;
        border-radius: 90px;
        padding: 27px 17px;
        transform: scale(1);
        transition: all ease 0.4s;
        @include media-breakpoint-down(xs) {
          padding: 3px 3px;
        }
      }
    }

    &:hover {
      .zone-img {
        img {
          transform: scale(1.05);
          transition: all ease 0.4s;
        }
      }
    }
  }

  .block-big-g {
    position: absolute;
    left: 40px;
    bottom: 0;
    height: 396px;
    width: 229px;
    opacity: 0.7;
    @include media-breakpoint-down(md) {
      left: -100px;
      opacity: 0.4;
    }
  }

  @include media-breakpoint-down(md) {
    .wavesvg {
      display: none;
    }
  }
}

.block-points {
  .item {
    background-color: white;
    border-radius: 120px;
    display: flex;
    padding: 50px 50px;
    @include media-breakpoint-down(md) {
      border-radius: 30px;
      padding: 10px 20px;
      display: block;
      text-align: center;
    }

    .icon {
      height: 70px;
      width: 70px;
      min-height: 70px;
      min-width: 70px;
      border-radius: 50%;
      background: $red;
      margin: 30px 30px 30px 0;
      @include media-breakpoint-down(md) {
        margin: 20px auto;
      }

      img {
        padding: 16px;
        display: block;
        stroke: white;
      }
    }

    .info {
      float: left;
      @include media-breakpoint-down(md) {
        float: initial;
        margin-bottom: 30px;
      }

      h2 {
        font-family: "Mulat Addis";
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 31px;
        padding-bottom: 0;
        @include media-breakpoint-down(md) {
          margin: 20px auto;
        }
        @include media-breakpoint-down(sm) {
          font-size: 23px;
          line-height: 27px;
        }
      }

      .text {
        font-family: "NexaRegular";
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

.block-points-culture {
  .list {
    display: flex;
    width: 100%;
    justify-content: space-between;
    @include media-breakpoint-down(xl) {
      display: contents;
      justify-content: initial;
    }
    .item-zone-icon {
      border-radius: 284px;
      border: 1px solid #011a24;
      margin: auto;
      height: 100%;
      padding: 40px 0 80px 0;
      @include media-breakpoint-down(lg) {
        margin: auto;
      }
      .zone-img {
        .icon {
          width: 72px;
          height: 72px;
          display: flex;
          vertical-align: middle;
          align-items: center;
          margin: 30px auto;
          border-radius: 50px;
          background-color: #e62a34;
          img {
            width: 100%;
            max-width: 38px;
            max-height: 38px;
            margin: auto;
          }
        }
        .title {
          color: #011a24;
          text-align: center;
          font-family: Mulat Addis;
          font-size: 40px;
          font-style: normal;
          font-weight: 400;
          line-height: 44.4px;
          letter-spacing: 0.8px;
          @include media-breakpoint-down(xl) {
            font-size: 30px;
            line-height: 34px;
          }
        }
        .text {
          color: #011a24;
          text-align: center;
          font-family: NexaRegular;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          @include media-breakpoint-down(xl) {
            font-size: 12px;
          }
        }
      }
    }
  }
}

.block-text-fond-bleu {
  position: relative;
  background-color: $dark-green;
  @include media-breakpoint-down(md) {
    text-align: center;
  }

  h2 {
    color: #fff;
    text-align: center;
    font-family: Nexa Bold;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 3px;
    text-transform: uppercase;
  }

  .text {
    color: #fff;
    text-align: center;
    font-family: Mulat Addis;
    font-size: 56px;
    font-style: normal;
    font-weight: 400;
    line-height: 60px;
    letter-spacing: 1.12px;
    @include media-breakpoint-down(lg) {
      font-size: 38px;
      line-height: initial;
    }
    @include media-breakpoint-down(md) {
      font-size: 24px;
    }
  }

  @include media-breakpoint-down(md) {
    .wavesvg {
      display: none;
    }
  }
}

.block-outils {
  text-align: center;

  h2 {
    color: black;
    z-index: 1;
    position: relative;
    padding-top: 20px;
    text-align: center;
  }

  .item-zone-img {
    z-index: 11;
    position: relative;
    img {
      background-color: white;
      border-radius: 90px;
      padding: 27px 17px;
      transform: scale(1);
      transition: all ease 0.4s;
      @include media-breakpoint-down(xs) {
        padding: 3px 3px;
      }
    }

    &:hover {
      img {
        transform: scale(1.05);
        transition: all ease 0.4s;
      }
    }
  }

  .block-big-g {
    position: absolute;
    left: 40px;
    bottom: 0;
    height: 396px;
    width: 229px;
    opacity: 0.7;
    @include media-breakpoint-down(md) {
      left: -100px;
      opacity: 0.4;
    }
  }

  @include media-breakpoint-down(md) {
    .wavesvg {
      display: none;
    }
  }
}

.block-chiffres {
  .number {
    font-family: "Mulat Addis";
    font-style: normal;
    font-weight: 400;
    font-size: 104px;
    line-height: 64px;
    text-align: center;
    @include media-breakpoint-down(sm) {
      font-size: 64px;
      line-height: 64px;
    }
    @include media-breakpoint-down(xs) {
      font-size: 44px;
      line-height: 44px;
    }
  }

  .text {
    font-family: "NexaRegular";
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    @include media-breakpoint-down(xs) {
      font-size: 12px;
      line-height: 17px;
    }
  }
}

.block-team {
  h2 {
    @include media-breakpoint-down(xl) {
      font-size: 80px;
      line-height: 85px;
    }
    @include media-breakpoint-down(lg) {
      font-size: 60px;
      line-height: 65px;
    }
    @include media-breakpoint-down(md) {
      font-size: 40px;
      line-height: 50px;
    }
  }

  .membre {
    cursor: pointer;
    padding-bottom: 40px;

    &:after {
      content: "";
      background-image: url("../img/icon/rounded.svg");
      width: 82px;
      height: 128px;
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      @include media-breakpoint-down(sm) {
        width: 82px;
        height: 97px;
      }
    }

    .block-img {
      width: 250px;
      height: 321px;
      border-radius: 170px;
      display: block;
      overflow: hidden;
      margin-bottom: 20px;
      @include media-breakpoint-down(sm) {
        width: 160px;
        height: 200px;
      }
      @include media-breakpoint-down(xs) {
        width: 130px;
        height: 170px;
      }

      .bg {
        width: 250px;
        height: 321px;
        border-radius: 170px;
        background-position: center;
        background-size: cover;
        position: relative;
        display: block;
        @include media-breakpoint-down(sm) {
          width: 160px;
          height: 200px;
        }
        @include media-breakpoint-down(xs) {
          width: 130px;
          height: 170px;
        }

        &.photo1 {
          position: absolute;
          top: 0;
          opacity: 1;
          z-index: 1;
          transition: all ease 1s;
        }

        &.photo2 {
          position: absolute;
          top: 0;
          opacity: 0;
          z-index: 2;
          transition: all ease 1s;
        }
      }
    }

    h3 {
      font-family: "Mulat Addis";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 31px;
      color: black;
      @include media-breakpoint-down(sm) {
        font-size: 18px;
        line-height: 22px;
      }
    }

    .post {
      font-family: "NexaRegular";
      font-size: 14px;
      line-height: 24px;
      color: black;
      padding-top: 6px;
      display: block;
    }

    &:hover {
      .bg {
        &.photo1 {
        }

        &.photo2 {
          opacity: 1;
          position: relative;
          transition: all ease 1s;
        }
      }
    }

    .plus {
      height: 72px;
      width: 72px;
      border-radius: 100px;
      background-color: $red;
      position: absolute;
      bottom: 120px;
      display: block;
      z-index: 3;
      @include media-breakpoint-down(sm) {
        height: 40px;
        width: 40px;
        bottom: initial;
        top: 130px;
      }

      &:before {
        content: "+";
        font-family: "Mulat Addis";
        color: white;
        z-index: 11;
        padding: 15px 20px 18px 18px;
        display: block;
        font-size: 52px;
        line-height: 32px;
        font-weight: 100;
        transition: all ease 0.4s;
        @include media-breakpoint-down(sm) {
          font-size: 38px;
          padding: 0px 20px 18px 7px;
        }
      }
    }

    &:hover {
      .plus {
        &:before {
          font-size: 64px;
          line-height: 32px;
          padding: 13px 20px 18px 13px;
          transition: all ease 0.4s;
          @include media-breakpoint-down(sm) {
            font-size: 48px;
            padding: 0px 20px 18px 3px;
          }
        }
      }
    }
  }
}

.block-adress {
  padding-bottom: 80px;
  @include media-breakpoint-down(md) {
    text-align: center;
  }

  .text {
    font-family: "NexaRegular";
    font-size: 16px;
    line-height: 24px;
  }

  .zone-img {
    @include media-breakpoint-down(md) {
      width: 100%;
      display: block;
      margin: auto;
    }

    .block-img {
      height: 640px;
      width: 460px;
      display: block;
      overflow: hidden;
      border-radius: 300px;
      position: relative;
      float: right;
      @include media-breakpoint-down(md) {
        height: 320px;
        width: 100%;
        border-radius: 30px;
      }

      .bg {
        background-position: center;
        background-size: cover;
        height: 640px;
        width: 460px;
        border-radius: 300px;
        transform: scale(1);
        transition: all ease 0.5s;
        float: right;
        @include media-breakpoint-down(md) {
          height: 320px;
          width: 100%;
          border-radius: 30px;
        }
      }

      &:hover {
        .bg {
          transform: scale(1.1);
          transition: all ease 0.5s;
        }
      }
    }

    .block-btn {
      position: absolute;
      top: 0;
      @include media-breakpoint-down(md) {
        position: absolute;
        top: 70px;
        left: 0;
        right: 0;
        margin: auto;
        display: inline-table;
      }
    }
  }

  .block-big-g {
    position: absolute;
    bottom: -108px;
    right: 7px;
    height: 260px;
    width: 149px;
    @include media-breakpoint-down(xl) {
      right: 7px;
    }
    @include media-breakpoint-down(md) {
      right: 16px;
      height: 130px;
      width: 74px;
      bottom: -28px;
    }
  }

  .pin,
  .phone {
    height: 64px;
    width: 64px;
    float: left;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.02);

    img {
      padding: 20px 0px 0px 20px;
      @include media-breakpoint-down(md) {
        padding: 17px 0px 0px 1px;
      }
    }
  }

  .infos {
    display: flex !important;
    vertical-align: middle;
    align-items: center;
    min-height: 60px;
    font-family: "NexaRegular";
    font-size: 14px;
    line-height: 24px;
  }
}
.block-news {
  .block-more {
    h2 {
      font-style: normal;
      font-weight: 400;
      font-size: 90px;
      line-height: 90px;
      letter-spacing: 0.02em;
      padding-bottom: 15px;
      color: #000000;
      @include media-breakpoint-down(md) {
        font-size: 56px;
        line-height: 78px;
      }
      @include media-breakpoint-down(sm) {
        font-size: 48px;
        line-height: 60px;
      }
      @include media-breakpoint-down(xs) {
        font-size: 34px;
        line-height: 40px;
      }
    }
  }
}
.block-why {
  .value {
    color: var(--fill-red, #e62a34);
    font-family: Mulat Addis;
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: initial;
    letter-spacing: 1.28px;
    @include media-breakpoint-down(lg) {
      font-size: 48px;
    }
    @include media-breakpoint-down(md) {
      font-size: 34px;
    }
    &:not(:last-child) {
      margin-bottom: 30px;
      @include media-breakpoint-down(md) {
        margin-bottom: 15px;
      }
    }
  }
  .zone-img {
    @include media-breakpoint-down(md) {
      width: 100%;
      display: block;
      margin: auto;
    }

    .block-img {
      height: 640px;
      width: 460px;
      display: block;
      overflow: hidden;
      border-radius: 300px;
      position: relative;
      float: right;
      @include media-breakpoint-down(md) {
        height: 320px;
        width: 100%;
        border-radius: 30px;
      }

      .bg {
        background-position: center;
        background-size: cover;
        height: 640px;
        width: 460px;
        border-radius: 300px;
        transform: scale(1);
        transition: all ease 0.5s;
        float: right;
        @include media-breakpoint-down(md) {
          height: 320px;
          width: 100%;
          border-radius: 30px;
        }
      }

      &:hover {
        .bg {
          transform: scale(1.1);
          transition: all ease 0.5s;
        }
      }
    }

    .block-btn {
      position: absolute;
      top: 0;
      z-index: 1;
      @include media-breakpoint-down(md) {
        position: absolute;
        top: 70px;
        left: 0;
        right: 0;
        margin: auto;
        display: inline-table;
      }
    }
  }
  .block-big-g {
    position: absolute;
    bottom: -108px;
    right: 7px;
    height: 260px;
    width: 149px;
    @include media-breakpoint-down(xl) {
      right: 7px;
    }
    @include media-breakpoint-down(md) {
      right: 16px;
      height: 130px;
      width: 74px;
      bottom: -28px;
    }
  }
}
