/*
|--------------------
|      FOOTER
|--------------------
*/
#footer {
  background-color: #f8fafc;
  padding: 40px 0;

  .logo-footer {
    height: 80px;
    width: 100%;
    margin-top: 70px;
    max-width: initial;
  }

  .offset-footer {
    margin-top: -33px;
    margin-bottom: 25px;
    @include media-breakpoint-down(md) {
      margin-top: 37px;
      margin-bottom: 0;
    }
  }

  .ft_block_adress {
    @include media-breakpoint-down(sm) {
      width: 100%;
      margin: auto;
    }

    .block-img {
      width: 163px;
      height: 209px;
      float: left;
      overflow: hidden;
      border-radius: 100px;
      @include media-breakpoint-down(lg) {
        width: 148px;
      }
      @include media-breakpoint-down(md) {
        width: 125px;
        height: 180px;
        margin-top: 10px;
      }
      @include media-breakpoint-down(sm) {
        width: 95px;
        height: 95px;
        margin: auto;
        float: inherit;
      }

      .img {
        width: 163px;
        height: 209px;
        transform: scale(1);
        background-size: cover;
        transition: all ease 0.4s;
        @include media-breakpoint-down(lg) {
          width: 148px;
        }
        @include media-breakpoint-down(md) {
          width: 125px;
          height: 180px;
        }
        @include media-breakpoint-down(sm) {
          width: 95px;
          height: 95px;
          margin-top: 0px;
        }
      }
    }

    .infos {
      height: 209px;
      padding: 0 20px;
      @include media-breakpoint-down(md) {
        padding: 0 12px;
      }
      @include media-breakpoint-down(sm) {
        height: initial;
        padding: 8px 12px 28px 12px;
        text-align: center;
        display: block !important;
      }

      .city {
        font-family: "Mulat Addis";
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 36px;
        padding-bottom: 10px;
        color: #000;
        @include media-breakpoint-down(xl) {
          font-size: 29px;
        }
        @include media-breakpoint-down(lg) {
          font-size: 25px;
        }
        @include media-breakpoint-down(md) {
          font-size: 21px;
        }
      }

      .adresse {
        font-family: "NexaRegular";
        font-size: 14px;
        line-height: 24px;
        color: black;
      }
    }

    &:hover {
      .img {
        transform: scale(1.08);
        transition: all ease 0.4s;
      }
    }
  }

  hr {
    opacity: 0.5;
    background-color: grey;
  }

  .copy {
    .item {
      font-family: "Mulat Addis";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;

      &:first-child {
        pointer-events: none;
        a {
          color: $red;
        }
      }

      a {
        font-family: "Mulat Addis";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        transition: all ease 0.3s;

        &:hover {
          color: $red;
          transition: all ease 0.3s;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      text-align: center;
    }
  }
}
