/*
|--------------------
|       Contact
|--------------------
*/

#page-contact {
  .scrolldown {
    top: calc(80vh - 270px);
  }

  .down {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: calc(80vh - 200px);
    transition: initial;
  }

  .big-g-banner {
    height: 600px;
    width: 350px;
    position: absolute;
    right: 50px;
    bottom: -10px;
    opacity: 0.8;

  }
}
