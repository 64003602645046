/*
|---------------------------------------------------------------
| COLORS
|---------------------------------------------------------------
| Define colors
|
|
*/

/*
|
| Colors declaration
|----------------------
|
*/
$black: #000000;
$very-dark-grey: #141414;
$dark-grey: #424242;
$grey: #999;
$light-grey: #dedede;
$very-light-grey: #fafafa;

$dark-green: #011a24;
$white: #ffffff;
$red: #e62a34;
$light-blue: #f8fbff;
$dark-blue: #011a24;

$white-opacity: rgba(255, 255, 255, 0.8);
$black-opacity: rgba(0, 0, 0, 0.6);

/*
|
| Assign colors
|---------------
|
*/
$border-color: $grey;
$text-color: $very-dark-grey;
$default-background-overlay: $black-opacity;

/*
|-------------
| Colors map 
|-------------
| - How to use: add lines to create more "color classes" 
| - Generated classes: .bg-{color} & .c-{color}
|
*/
$colors: (
  //Required colors
  "black": $black,
  "very-dark-grey": $very-dark-grey,
  "grey": $grey,
  "dark-grey": $dark-grey,

  "dark-green": $dark-green,
  "white": $white,
  "red": $red,
  "light-blue": $light-blue,
  "dark-blue": $dark-blue,

  "light-grey": $light-grey,
  "very-light-grey": $very-light-grey
);
